import React from "react";
import "./Footer.css";
import footerlogo from "./footerlogo.svg";
import footerimg from "./footerimg.png";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

function Footer(props) {
  return (
    <div>
      <section class="footer position-relative">
        {/* <img src={footerimg} className="footerimg" alt="" /> */}
        <div class="container">
          <div class="footer-row">
            <div class="footercol">
              <div class="position-relative flex-fill component__footer--brand">
                <ul>
                  <li class="mr-3">
                    <img src={footerlogo} class="footerlogo" alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="footercol">
              <div class="position-relative flex-fill">
                <ul class="ul__list mt-2">
                  <li
                    class="color__white font__size--18 mb-4 sans-semibold component__border--botom position-relative wow bounceInUp"
                    data-wow-delay="0.3s"
                  >
                    <strong>ABOUT</strong>
                  </li>
                  <li class="footerlink wow bounceInUp" data-wow-delay="0.4s">
                    <a href="#logo" class="footerlink">
                      About
                    </a>
                  </li>
                  <li class="footerlink wow bounceInUp" data-wow-delay="0.5s">
                    <a href="#services" class="footerlink">
                      What I do
                    </a>
                  </li>

                  <li class="footerlink wow bounceInUp" data-wow-delay="0.7s">
                    <a href="#portfolio" class="footerlink">
                      Projects
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footercol">
              <div class="position-relative flex-fill">
                <ul class="ul__list mt-2">
                  <li
                    class="color__white font__size--18 mb-4 sans-semibold component__border--botom position-relative wow bounceInUp"
                    data-wow-delay="0.3s"
                  >
                    <strong>Get in Touch</strong>
                  </li>
                  <li class="footerlink wow bounceInUp" data-wow-delay="0.4s">
                    <a
                      href="https://www.facebook.com/rupakthapa.droiddynasty/"
                      class="footerlink"
                    >
                      Facebook
                    </a>
                  </li>
                  <li class="footerlink wow bounceInUp" data-wow-delay="0.5s">
                    <a
                      href="https://www.instagram.com/rupak_thapa/"
                      class="footerlink"
                    >
                      Instagram
                    </a>
                  </li>

                  <li class="footerlink wow bounceInUp" data-wow-delay="0.7s">
                    <a href="https://github.com/RupakThapa/" class="footerlink">
                      Github
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <p class="m-0 color__white opacity__6 font__size--14 text-center d-block d-md-none">
            Made with ❤ by Rupak Thapa. Images from{" "}
            <a href="" rel="nofollow" style={{ color: "white" }}>
              Freepik
            </a>
          </p>
        </div>
      </section>
      <div className="topbtn">
        <h2>
          <a href="#logo">
            <ExpandLessIcon className="topbtnicon" />
          </a>
        </h2>
      </div>
    </div>
  );
}

export default Footer;
