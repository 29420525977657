import React from "react";
import "./Header.css";
import logo from "./logo.svg";
import GitHubIcon from "@material-ui/icons/GitHub";

function Header(props) {
  return (
    <div className="container navbar">
      <div className="logo" id="logo">
        <img src={logo} />
      </div>
      <ul className="navitemgroup">
        <li className="navitem upper">
          <a href="#services">Services</a>
        </li>
        <li className="navitem upper">
          <a href="#portfolio">Portfolio</a>
        </li>
        <li className="navitem upper">
          <a href="#contact">Contact</a>
        </li>
        <div className="github">
          <GitHubIcon className="githubicon" />
          <a href="https://github.com/RupakThapa">Github</a>
        </div>
      </ul>
    </div>
  );
}

export default Header;
