import React from "react";
import "./optin.css";
import GitHubIcon from "@material-ui/icons/GitHub";

function Optin(props) {
  return (
    <div className="optin">
      <div className="github">
        <GitHubIcon className="githubicon" />
        <a href="https://github.com/RupakThapa">Github</a>
      </div>
      <div className="github">
        <GitHubIcon className="githubicon" />
        <a href="https://www.facebook.com/iamrupakthapa/">
          Contact via Facebook
        </a>
      </div>
    </div>
  );
}

export default Optin;
