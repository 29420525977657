import React from "react";
import "./Card.css";
import styled from "styled-components";
import services from "./service3.png";

const Fakethumb = styled.div`
  height: 200px;
  width: 100%;
  /* background: red; */
  background: url(${(props) => props.thumb});
  /* border-radius: 0.99em; */
  object-fit: cover;
`;

function Card(props) {
  return (
    <div className="card">
      {/* <div className="fakethumb"></div> */}
      {/* <div className="thumbholder">
        <Fakethumb className="fakethumb" thumb={props.thumb} />
      </div> */}
      <div class="cardthumb">
        <img src={props.thumb} alt="" />
      </div>
      <div className="cardcontent">
        <h5 class="card-heading">{props.title}</h5>
        <p class="card-text">{props.desc}</p>
        <div className={props.btnclr}>
          <a href="#contact">Learn More</a>
        </div>
      </div>
    </div>
  );
}

export default Card;
