import React from "react";
import "./Contact.css";
import Optin from "./Optin";

function Contact(props) {
  return (
    <div id="contact">
      <div class="container">
        <div className="section-heading">
          <h4>Any Questions</h4>
          <p>
            If you have any queries, feel free to contact me. Please mention
            your queries thoroughly so that i can serve it better.
          </p>
        </div>
      </div>

      <Optin />

      {/* <div class="container">
        <div class="contactrow">
          <div class="contactcol-left">
            <div class="bg-white">
              <h3>Send Message</h3>
              <hr />

              <form action="">
                <div class="row mb-md-3">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" placeholder="Your Name" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="email" placeholder="Your Email" />
                    </div>
                  </div>
                </div>

                <div class="form-group position-relative">
                  <textarea placeholder="Your Message"></textarea>
                  <button
                    class="bg__purple no__outline component__button--send wow bounceInUp"
                    data-wow-delay="0.6s"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Send"
                  >
                    <img src="assets/images/send 2.png" alt="" />
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="contactcol-right">
            <div class="bg-white">
              <h5
                class="sans-bold color__black opacity__5 font__size--20 wow bounceInLeft"
                data-wow-delay="0.3s"
              >
                Contact Information
              </h5>
              <hr class="opacity__6 mt-3 mb-4" />

              <ul class="ul__list mb-5">
                <li class="contact-listitem" data-wow-delay="0.4s">
                  <div class="row">
                    <div class="col-3">
                      <img
                        src="assets/images/Group.png"
                        class="image__sm-5-w"
                        alt=""
                      />
                    </div>
                    <div class="col-9 pl-0">
                      <div class="sans-bold align-middle color__black opacity__5 hover">
                        hello@oracle.com
                      </div>
                    </div>
                  </div>
                </li>
                <li class="contact-listitem">
                  <div class="row">
                    <div class="col-3">
                      <img
                        src="assets/images/Vector.png"
                        class="image__sm-5-w"
                        alt=""
                      />
                    </div>
                    <div class="col-9 pl-0">
                      <div class="sans-bold align-middle color__black opacity__5 hover">
                        www.oracle.com
                      </div>
                    </div>
                  </div>
                </li>
                <li class="contact-listitem">
                  <div class="row">
                    <div class="col-3">
                      <img
                        src="assets/images/Group 622.png"
                        class="image__sm-5-w"
                        alt=""
                      />
                    </div>
                    <div class="col-9 pl-0">
                      <div class="sans-bold align-middle color__black opacity__5 hover">
                        Sudirman street, holgan, melbourne
                      </div>
                    </div>
                  </div>
                </li>
                <li class="contact-listitem" data-wow-delay="0.7s">
                  <div class="row">
                    <div class="col-3">
                      <img
                        src="assets/images/Group-1.png"
                        class="image__sm-5-w"
                        alt=""
                      />
                    </div>
                    <div class="col-9 pl-0">
                      <div class="sans-bold align-middle color__black opacity__5 hover">
                        0361 - 8878 - 4343
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <h5
                class="sans-bold color__black opacity__5 font__size--20 wow bounceInLeft"
                data-wow-delay="0.8s"
              >
                Social Media
              </h5>
              <hr class="opacity__6 mt-3 mb-4" />

              <div class="row justify-content-center justify-content-lg-start">
                <div class="col-6 mb-3 mb-sm-0 text-center text-sm-left col-sm-3 col-md-2 col-lg-3">
                  <div
                    class="component__icon--contact mx-auto wow bounceInUp"
                    data-wow-delay="0.9s"
                  >
                    <a href="#!">
                      <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <div class="col-6 mb-3 mb-sm-0 text-center text-sm-left col-sm-3 col-md-2 col-lg-3">
                  <div
                    class="component__icon--contact mx-auto wow bounceInUp"
                    data-wow-delay="0.10s"
                  >
                    <a href="#!">
                      <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <div class="col-6 mb-3 mb-sm-0 text-center text-sm-left col-sm-3 col-md-2 col-lg-3">
                  <div
                    class="component__icon--contact mx-auto wow bounceInUp"
                    data-wow-delay="0.11s"
                  >
                    <a href="#!">
                      <i class="fa fa-dribbble" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <div class="col-6 mb-3 mb-sm-0 text-center text-sm-left col-sm-3 col-md-2 col-lg-3">
                  <div
                    class="component__icon--contact mx-auto wow bounceInUp"
                    data-wow-delay="0.12s"
                  >
                    <a href="#!">
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div> */}
    </div>
  );
}

export default Contact;
