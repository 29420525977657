import React from "react";
import "./Projects.css";
import proj1 from "./proj1.png";
import proj2 from "./proj2.png";
import proj3 from "./proj3.png";

function Projects(props) {
  return (
    <div id="portfolio">
      <div class="container">
        <div class="row">
          <div className="section-heading">
            <h4>Portfolio</h4>
            <p>
              Here are some projects related to Front-end development using
              React and side projects.
            </p>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="bg-yellow">
          <div class="proj-row">
            <div class="col-projects-left">
              <div className="proj-title-group">
                <h4>Daraz Clone</h4>
                <p>
                  This is the clone of popular ecommerce site in Nepal called
                  Daraz.Cart Functionality includes Login/Register,Apply Promo
                  codes,Change Username. I have used Local JSON data, Contentful
                  API call with Axios and Fetch,Context Api, React
                  router,Material icons, Styled Components and Firebase
                  Authentication and hosting.
                </p>
              </div>

              <div className="proj-btn-group">
                <hr />
                <br />

                <a
                  className="btn btn-third-sec"
                  href="https://daraz.rupak-thapa.com.np/"
                >
                  LIVE DEMO
                </a>

                <a
                  className="btn btn-third-pri"
                  href="https://github.com/RupakThapa/daraz-clone"
                >
                  Github
                </a>
              </div>
            </div>
            <div class="col-projects-right">
              <img src={proj1} className="proj-image" />
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="bg-blue">
          <div class="proj-row">
            <div class="col-projects-left">
              <div className="proj-title-group">
                <h4>React Image Gallery</h4>
                <p>
                  This is basic gallery website with funcationalities such as
                  Load Images from Local JSON data. For Loading a large amount
                  of data Lazy load has been implemented. A Modal Popup is used
                  to view the image individually. While it was not absolute
                  necessary, I have used a separate links for each image
                  download after closing the Modal.
                </p>
              </div>
              <div className="proj-btn-group">
                <hr />
                <br />

                <a
                  className="btn btn-third-sec1"
                  href="https://gallerywebapp.rupak-thapa.com.np/"
                >
                  LIVE DEMO
                </a>

                <a
                  className="btn btn-third-pri1"
                  href="https://github.com/RupakThapa/React-Image-Gallery"
                >
                  Github
                </a>
              </div>
            </div>
            <div class="col-projects-right">
              <img src={proj2} className="proj-image" />
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="bg-pink">
          <div class="proj-row">
            <div class="col-projects-left">
              <div className="proj-title-group">
                <h4>Consultancy Website</h4>
                <p>
                  This site is a project i got to hands on during my Internship.
                  The foremost requirement was to retain most of the old design
                  and implementing a simple backend. The backend is PHP based
                  for publishing study destinations.
                </p>
              </div>
              <div className="proj-btn-group">
                <hr />
                <br />

                <a
                  className="btn btn-third-sec2"
                  href="https://consultancy.rupak-thapa.com.np/"
                >
                  LIVE DEMO
                </a>

                <a
                  className="btn btn-third-pri2"
                  href="https://github.com/RupakThapa/BagbazarConsultancy"
                >
                  Github
                </a>
              </div>
            </div>
            <div class="col-projects-right">
              <img src={proj3} className="proj-image" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
