import "./App.css";
import Contact from "./Contact";
import Footer from "./Footer";
import Header from "./Header";
import Jumbo from "./Jumbo";
import Optin from "./Optin";
import Projects from "./Projects";
import Services from "./Services";
import Wave from "./Wave";

function App() {
  return (
    <div className="App">
      <Header></Header>

      <Jumbo></Jumbo>
      <Services></Services>
      <Projects></Projects>

      <Contact></Contact>
      <Wave />
      <Footer></Footer>
    </div>
  );
}

export default App;
