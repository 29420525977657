import React from "react";
import Card from "./Card";
import "./Services.css";
import service1 from "./service1.png";
import service2 from "./service2.png";
import service3 from "./service3.png";

function Services(props) {
  return (
    <div id="services">
      <div class="container">
        <div className="section-heading">
          <h4>What I do</h4>
          <p>
            I am visual guy who loves to design as well as develop beautiful
            websites.{" "}
          </p>
        </div>
      </div>

      <section class="pt-4">
        <div class="container">
          <div class="services">
            <div class="card-group">
              <Card
                className="thumb1"
                thumb={service1}
                title="Front-end Development"
                desc="I can convert your dream design to functional website"
                btnclr="btn-fifth"
              ></Card>
              <Card
                className="thumb2"
                thumb={service2}
                title="UX/UI Design"
                // desc="I can convert your dream design to functional website"
                desc="I can design UI that improves visual aspect as well as usability"
                btnclr="btn-fourth"
              ></Card>
              <Card
                className="thumb3"
                thumb={service3}
                title="Search Engine Optimization"
                desc="I can optimize your site and help to rank your site on Google"
                btnclr="btn-third"
              ></Card>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
