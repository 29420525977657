import React from "react";
import "./Jumbo.css";
import image1 from "./main.png";

function Jumbo(props) {
  return (
    <div>
      <section class="newsection">
        <div class="container">
          <div class="row">
            <div class="jumbo-left">
              <h2>
                I am <br />
                <span style={{ color: "#7066df", margin: 0, padding: 0 }}>
                  Front-End
                </span>
                <br />
                Developer
              </h2>
              <p>
                I am Rupak Thapa. If you want to design and develop stunning
                Websites, Search Engine Optimization then Feel free to leave me
                a message. contact me.
              </p>

              <div class="btn-group">
                <div class="btn btn-main">
                  <a href="https://www.facebook.com/iamrupakthapa/" class="">
                    Contact Me
                  </a>
                </div>
                <div class="btn btn-secondary">
                  <a
                    href="https://drive.google.com/file/d/1A1hichr7wxht_h5ahBAXLa90a5cgg2m2/view?usp=sharing"
                    class=""
                  >
                    Resume
                  </a>
                </div>
              </div>
              <br />
              <p style={{ fontSize: "12px" }}>
                **For Resume just use Request access button with your
                organization name
              </p>
            </div>
            <div class="jumbo-right">
              <img src={image1} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Jumbo;
